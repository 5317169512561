<template>
  <v-app style="background-color: #F2F2F2">
    <v-layout row wrap align-center justify-center v-if="loader === false">
      <v-flex lg4 md5 sm10 xs9>
        <v-card class="mt-5 mb-5 elevation-3" outlined style="border-radius: 20px">
          <v-card-actions class="pt-0 pb-0 mt-0 mb-0">
            <v-spacer></v-spacer>
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab text v-on="on" small>
                  <flag :iso="defaultflag" :squared="false" />
                </v-btn>
              </template>
              <v-list nav dense>
                <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
                  <v-list-item-action>
                    <flag :iso="item.flag" :squared="false" />
                  </v-list-item-action>
                  <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
          <!-- <v-card-text>
                        Hello World
          </v-card-text>-->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="success"
          ></v-progress-linear>
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 md10 lg10 class="pa-0">
              <v-card-text class="text-center">
                <img
                  src="@/assets/logo_oneboxbusiness-horizontal.png"
                  width="45%"
                  height="45%"
                  alt="OneBoxBusinesså"
                />
              </v-card-text>
              <!-- <v-card-text class="text-center">
                                <h2>เข้าสู่ระบบ</h2>
              </v-card-text>-->
              <v-card-text class="text-right">
                <v-text-field
                  outlined
                  solo
                  hide-details
                  flat
                  prepend-inner-icon="account_box"
                  :placeholder="$t('username')"
                  dense
                  @keyup.enter="login"
                  :disabled="loading"
                  v-model.trim="username"
                ></v-text-field>
                <br />
                <v-text-field
                  prepend-inner-icon="lock"
                  outlined
                  solo
                  flat
                  :placeholder="$t('password')"
                  dense
                  hide-details
                  :disabled="loading"
                  v-model.trim="password"
                  @keyup.enter="login"
                  @click:append="showpassword = !showpassword"
                  :type="showpassword ? 'text' : 'password'"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                ></v-text-field>
                <div class="pt-3">
                  <a
                    :href=" urloneid +'/type_forgot_password'"
                    style="text-decoration: none;"
                  >{{$t('forgotpassword')}}</a>
                </div>
              </v-card-text>
              <v-card-text class="pt-0">
                <v-btn
                  block
                  rounded
                  class="elevation-1"
                  color="primary"
                  @click="login"
                  :disabled="loading"
                  :loading="loading"
                >{{ $t('login')}}</v-btn>
              </v-card-text>
            </v-flex>
          </v-layout>
          <br />
          <v-divider></v-divider>
          <v-card-text class="text-center">Powered by One Collaboration</v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center justify-center v-else>
      <v-flex lg4 md5 sm7 xs7>
        <v-progress-linear
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
      </v-flex>
    </v-layout>

    <div v-if="dialog === true">
      <v-dialog v-model="dialog" scrollable max-width="400px">
        <v-card>
          <v-card-text style="height: 320px" class>
            <div class="ma-6 pa-0 text-center">
              <img src="@/assets/notification.png" width="100px" height="100px" alt="One Box" />
            </div>
            <div class="text-center">
              <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
            </div>
            <div>
              <br />
              <span style="font-size: 16px;">
                หากท่านเคย upload ข้อมูลไว้ใน One Box กรุณาย้ายข้อมูลของท่านจาก One Box เดิม (
                <a target="_blank" href="https://archivebox.one.th">archivebox.one.th</a>) มาที่
                <a target="_blank" href="https://onebox.thaimediafund.or.th">box.one.th</a> ภายในวันที่ 30 เมษายน พ.ศ.2563
              </span>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="text-center pa-2">
            <v-btn
              color="green darken-1"
              class="white--text"
              rounded
              depressed
              @click="dialog = false"
            >รับทราบ</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en"
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th"
        }
      ],
      urloneid: process.env.VUE_APP_ONEID,
      loader: true,
      loading: false,
      username: "",
      password: "",
      showpassword: false,
      dialog: false
    };
  },
  methods: {
    login() {
      this.loading = true;

      let payload = {
        username: this.username,
        password: this.password
      };

      // this.$router.push('/mydrive')
      this.$store
        .dispatch("authorize_login", payload)
        .then(msg => {
          // console.log(msg);
          this.loading = false;
          if (msg.status === "Access Granted") {
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "username",
            });

            VueCookies.set("checklogin", true, 60 * 60 * 24); // เพิ่มเพราะไว้ดักในหน้าหลัก
            this.$router.push("/business/allinbox");
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              title: "Username หรือ Password ไม่ถูกต้อง"
            });
            this.loader = false;
          } else {
            Toast.fire({ icon: "error", title: msg.msg });
          }
        })
        .catch(err => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
          });
          this.loading = false;
        });
    },
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex(v => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    }
  },
  mounted() {
    setTimeout(() => {
      this.changelang(localStorage.getItem("lang"));
      if (this.$route.query.authorizebydomain === undefined) {
        this.$store
          .dispatch("authorize_checking")
          .then(msg => {
            // console.log(msg);
            setTimeout(() => {
              this.dialog = false;
            }, 800);
            if (msg.status === "Access Granted") {
              this.$router.push("/business/allinbox");
              this.loader = false;
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                title: "Username หรือ Password ไม่ถูกต้อง"
              });
              this.loader = false;
              //  this.dialog = true;
            } else {
              // Toast.fire({ icon: "error", title: msg.msg });
              this.loader = false;
              //  this.dialog = true;
            }
          })
          .catch(err => {
            setTimeout(() => {
              this.dialog = true;
            }, 800);
            console.log(err);
            //  this.dialog = true;
            this.loader = false;
          });
      } else {
        let email = { business_email: this.$route.query.authorizebydomain };
        this.$store
          .dispatch("authorize_login_business_email", email)
          .then(msg => {
            if (msg.status === "Access Granted") {
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: "username",
              });

              this.$router.push("/business/allinbox");
              this.loader = false;
            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                title: "Username หรือ Password ไม่ถูกต้อง"
              });
              this.loader = false;
            } else if (msg.code === "ER401") {
              Toast.fire({ icon: "error", title: "ไม่พบข้อมูลผู้ใช้งาน" });
              this.loader = false;
            } else {
              // Toast.fire({ icon: "error", title: msg.msg });
              this.loader = false;
            }
          })
          .catch(error => {
            this.loader = false;
            console.log(error);
          });
      }
    }, 1000);
  }
};
</script>

<style>
</style>